.about-section {
	.about-us-text {
		width: 100%;
		margin-bottom: 62px;
		color: $corpColor;
		@media screen and (max-width: $mobileBreakPoint) {
			margin-bottom: 13.333vw;
		}
		h2 {
			margin: 0 0 30px;
			font-size: 26px;
			line-height: 29px;
			text-align: center;
			font-weight: 500;
			width: 100%;
			@media screen and (max-width: $mobileBreakPoint) {
				margin: 0 0 5.333vw;
				font-size: 5.867vw;
				line-height: 6.133vw;
				text-align: left;
			}
		}
		p {
			margin: 0;
			font-size: 18px;
			line-height: 25px;
			font-weight: 500;
			text-align: center;
			width: 100%;
			@media screen and (max-width: $mobileBreakPoint) {
				font-size: 4vw;
				line-height: 4.533vw;
				text-align: left;
			}
		}
	}
	.cards {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		.card {
			width: 33.33333%;
			height: 265px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			@media screen and (max-width: $mobileBreakPoint) {
				height: 28.533vw;
				width: 50%;
			}
			&.logo {
				background: #f9f9f9;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 248px;
					@media screen and (max-width: $mobileBreakPoint) {
						width: 33.6vw;
					}
				}
			}
			&.counter {
				color: #fff;
				span.number {
					font-size: 120px;
					line-height: 138px;
					margin: 0;
					@media screen and (max-width: $mobileBreakPoint) {
						font-size: 10.667vw;
						line-height: 12.267vw;
					}
				}
				span.text {
					font-size: 18px;
					font-weight: 500;
					line-height: 20px;
					margin: 15px 0 0 0;
					@media screen and (max-width: $mobileBreakPoint) {
						font-size: 3.2vw;
						line-height: 3.733vw;
						margin: 2.667vw 0 0 0;
						text-align: center;
					}
				}
				&.logo-card {
					@media screen and (max-width: $mobileBreakPoint) {
						order: 1;
					}
				}
				&.workers {
					background: $green;
					@media screen and (max-width: $mobileBreakPoint) {
						order: 3;
					}
				}
				&.img-card-one {
					@media screen and (max-width: $mobileBreakPoint) {
						order: 4;
					}
				}
				&.produced {
					background: $orange;
					@media screen and (max-width: $mobileBreakPoint) {
						order: 2;
					}
				}
				&.img-card-two {
					@media screen and (max-width: $mobileBreakPoint) {
						order: 5;
					}
				}
				&.market {
					background: $blue;
					@media screen and (max-width: $mobileBreakPoint) {
						order: 6;
					}
				}
			}
			&.img {
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&.logo-card {
				@media screen and (max-width: $mobileBreakPoint) {
					order: 1;
				}
			}
			&.workers {
				background: $green;
				@media screen and (max-width: $mobileBreakPoint) {
					order: 3;
				}
			}
			&.img-card-one {
				@media screen and (max-width: $mobileBreakPoint) {
					order: 4;
				}
			}
			&.produced {
				background: $orange;
				@media screen and (max-width: $mobileBreakPoint) {
					order: 2;
				}
			}
			&.img-card-two {
				@media screen and (max-width: $mobileBreakPoint) {
					order: 5;
				}
			}
			&.market {
				background: $blue;
				@media screen and (max-width: $mobileBreakPoint) {
					order: 6;
				}
			}
		}
	}
	.points {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: flex-start;
		margin-top: 100px;
		margin-bottom: 100px;
		@media screen and (max-width: $mobileBreakPoint) {
			margin-top: 13.333vw;
			margin-bottom: 5.333vw;
			height: 53.333vw;
			flex-wrap: nowrap;
			align-items: flex-start;
			overflow-x: hidden;
			justify-content: flex-start;
		}
		.point {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: $mobileBreakPoint) {
				width: 100%;
				height: 100%;
				flex-shrink: 0;
				position: absolute;
				top: 0;
				left: 0;
				justify-content: flex-start;
				opacity: 0;
				transition: all 0.4s;
				&.active-slide {
					opacity: 1;
					transform: translateX(0);
				}
				&.last-slide {
					transform: (translateX(-100%));
				}
				&.next-slide {
					transform: translateX(100%);
				}
			}
			.icon-holder {
				width: 135px;
				height: 135px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #000;
				border-radius: 50%;
				@media screen and (max-width: $mobileBreakPoint) {
					width: 30.667vw;
					height: 30.667vw;
				}
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
			p {
				margin: 44px 0 0 0;
				color: $corpColor;
				font-weight: 500;
				font-size: 18px;
				line-height: 20px;
				max-width: 285px;
				text-align: center;
				@media screen and (max-width: $mobileBreakPoint) {
					font-size: 4vw;
					line-height: 4.8vw;
					max-width: 76vw;
					margin: 8vw 0 0 0;
				}
			}
			&.proffessionals {
				.icon-holder {
					background: $corpColorSecondary;
				}
			}
			&.safe {
				.icon-holder {
					background: $orange;
				}
			}
			&.quality {
				.icon-holder {
					background: $greenLighter;
				}
			}
		}
	}
	.slider-controls {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		.control {
			height: 100%;
			display: flex;
			align-items: center;
			transition: all 0.05s linear;
			img {
				width: 3.2vw;
			}
			&:active {
				transform: scale(0.9);
			}
			&.slide-left {
				padding-right: 2.667vw;
			}
			&.slide-right {
				padding-left: 2.667vw;
			}
		}
	}
}
