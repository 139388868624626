.map-section {
	.short-description-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		font-size: 16px;
		color: $corpColor;
		line-height: 18px;
		margin-bottom: 30px;
		@media screen and (max-width: $mobileBreakPoint) {
			display: none;
		}
		.title {
			font-weight: 500;
		}
	}
	.continent-wrap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;
		@media screen and (max-width: $mobileBreakPoint) {
			justify-content: space-between;
			margin-bottom: 6.933vw;
		}
		.continent {
			cursor: pointer;
			font-size: 18px;
			line-height: 20px;
			color: $corpColor;
			margin-right: 60px;
			padding-bottom: 6px;
			font-weight: 500;
			@media screen and (max-width: $mobileBreakPoint) {
				font-size: 3.733vw;
				line-height: 4.267vw;
				margin-right: 0;
				padding-bottom: 1.6vw;
			}
			&.active {
				border-bottom: 2px solid $corpColor;
				@media screen and (max-width: $mobileBreakPoint) {
					border-bottom: 0.533vw solid $corpColor;
				}
			}
		}
	}
	.map-wrap {
		width: 100%;
		margin-bottom: 100px;
		@media screen and (max-width: $mobileBreakPoint) {
			margin-bottom: 18.667vw;
		}
		.map {
			width: 100%;
			height: 500px;
			@media screen and (max-width: $mobileBreakPoint) {
				height: 133.333vw;
			}
		}
	}
}
