.products-section {
	.bg-wrap {
		background: #fff7e3;
	}
	.product-list-wrapper {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 100px;
		@media screen and (max-width: $mobileBreakPoint) {
			margin-bottom: 8vw;
		}
		.category {
			width: 100%;
			height: 357px;
			margin-bottom: 30px;
			display: flex;
			@media screen and (max-width: $mobileBreakPoint) {
				height: 45.867vw;
				margin-bottom: 3.2vw;
			}
			.img-holder {
				width: 100%;
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.more {
				flex-shrink: 0;
				width: 335px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: #fff;
				@media screen and (max-width: $mobileBreakPoint) {
					width: 100%;
					background: rgba($color: #000000, $alpha: 0.45);
					position: absolute;
					top: 0;
					left: 0;
				}
				img.bg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					@media screen and (max-width: $mobileBreakPoint) {
						display: none;
					}
				}
				.title {
					width: 200px;
					font-size: 32px;
					line-height: 36px;
					font-weight: 500;
					@media screen and (max-width: $mobileBreakPoint) {
						font-size: 4.8vw;
						line-height: 5.333vw;
						width: auto;
					}
				}
				p {
					font-size: 16px;
					line-height: 18px;
					margin: 28px 0;
					max-width: 200px;
					width: 200px;
					@media screen and (max-width: $mobileBreakPoint) {
						font-size: 4vw;
						line-height: 4.8vw;
						margin: 4.8vw 0;
						max-width: 53.333vw;
						width: auto;
						text-align: center;
					}
				}
				.btn {
					width: 164px;
					margin-left: -36px;
					@media screen and (max-width: $mobileBreakPoint) {
						margin-left: 0;
						width: auto;
						border: transparent;
						padding: 0;
						font-size: 3.2vw;
						padding-bottom: 1.6vw;
						border-bottom: 1px solid #fff;
						border-radius: 0;
						&:hover {
							background: transparent;
							color: #fff;
						}
					}
				}
			}
		}
	}
}
.product-detailed-modal {
	@media screen and (max-width: $mobileBreakPoint) {
		width: 100%;
		padding: 0;
	}
	.product-modal-container {
		width: $desktopGridWidht;
		padding: 65px 136px 90px;
		display: flex;
		flex-direction: column;
		@media screen and (max-width: $desktopGridWidht) {
			width: 100%;
			padding: 65px 50px 90px;
		}
		@media screen and (max-width: $mobileBreakPoint) {
			width: 100%;
			padding: 10.667vw 5.333vw;
			height: 100vh;
		}
		.bg {
			width: 100%;
			height: 100%;
			object-fit: cover;
			background: #f4f0e0;
			position: absolute;
			top: 0;
			left: 0;
		}
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 40px;
			@media screen and (max-width: $mobileBreakPoint) {
				margin-bottom: 8vw;
				flex-shrink: 0;
			}
			> .title {
				font-size: 32px;
				line-height: 33px;
				font-weight: 500;
				color: $corpColor;
				padding-bottom: 6px;
				border-bottom: 2px solid $corpColor;
				@media screen and (max-width: $mobileBreakPoint) {
					font-size: 5.867vw;
					line-height: 6.133vw;
					padding-bottom: 1.6vw;
					border-bottom: 0.533vw solid $corpColor;
				}
			}
			.close-button {
				width: 36px;
				cursor: pointer;
				margin-right: -16px;
				@media screen and (max-width: $mobileBreakPoint) {
					width: 5.867vw;
					margin-right: 0;
				}
			}
		}
		.body {
			width: 100%;
			display: flex;
			flex-direction: column;
			height: 620px;
			overflow-y: scroll;
			@include scrollbars(6px, 9px, $corpColor);
			@media screen and (max-width: $mobileBreakPoint) {
				@include scrollbars(0.533vw, 2.4vw, $corpColor);
				height: 100%;
				padding-right: 3.467vw;
			}
			.product-list {
				width: 100%;
				display: flex;
				flex-direction: column;

				.product {
					display: flex;
					align-items: center;
					margin-bottom: 50px;
					@media screen and (max-width: $mobileBreakPoint) {
						margin-bottom: 10.667vw;
						flex-direction: column;
						width: 100%;
					}
					> img {
						width: 280px;
						margin-right: 30px;
						flex-shrink: 0;
						@media screen and (max-width: $mobileBreakPoint) {
							width: 45.333vw;
							margin-right: 0;
							margin-bottom: 5.333vw;
						}
					}
					.details {
						max-width: 455px;
						width: 100%;
						display: flex;
						flex-direction: column;
						color: $corpColor;
						@media screen and (max-width: $mobileBreakPoint) {
							max-width: 100%;
						}
						> .title {
							font-size: 26px;
							line-height: 27px;
							margin: 0 0 30px;
							@media screen and (max-width: $mobileBreakPoint) {
								font-size: 4.8vw;
								line-height: 5.333vw;
								margin: 0 0 5.333vw;
								font-weight: 500;
								text-align: center;
							}
						}
						p {
							font-size: 16px;
							line-height: 18px;
							margin: 0 0 36px;
							width: 100%;
							max-width: 400px;
							@media screen and (max-width: $mobileBreakPoint) {
								font-size: 4vw;
								line-height: 4.8vw;
								margin: 0 0 9.333vw;
								max-width: 100%;
								text-align: center;
							}
						}
						.fields {
							width: 100%;
							display: flex;
							align-items: flex-start;
							.field {
								width: 100%;
								display: flex;
								flex-direction: column;
								font-size: 16px;
								line-height: 16px;
								@media screen and (max-width: $mobileBreakPoint) {
									font-size: 3.733vw;
									line-height: 3.733vw;
								}
								&-key {
									font-weight: 700;
									padding-bottom: 10px;
									margin-bottom: 10px;
									border-bottom: 1px solid $corpColor;
									@media screen and (max-width: $mobileBreakPoint) {
										padding-bottom: 3.2vw;
										margin-bottom: 3.2vw;
									}
								}
							}
						}
					}
					&:nth-child(even) {
						margin-left: 280px;
						@media screen and (max-width: $mobileBreakPoint) {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
